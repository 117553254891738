import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import logo from "../img/webwiseinnn.png";
import paypalLogo from '../iconos/paypal.png';
import anotherPaymentLogo from '../iconos/lemon.webp';
import mercado from '../iconos/mercado.png';
import usdt from '../iconos/usdt.png';

const PromotionalSection = () => {
  const handleContactButtonClick = () => {
    const phoneNumber = '+584241337562';
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, '_blank');
  };

  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div id='saber-mas-section' className="  text-white py-10 px-4 flex flex-col items-center justify-center min-h-screen"  style={{ background: 'linear-gradient(to top, #003d19, black, black,black,black,black)' }}

>
      <motion.h2
        className="text-2xl text-white py-2 px-4 rounded-lg mb-5 border-2 border-green-500 text-center w-52 mx-auto"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        PIDE TU IMPULSO
      </motion.h2>
      <motion.h2
        className="text-3xl md:text-5xl text-center mb-6 m-4 w-full md:w-1/4"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1, delay: 0.5 }}
      >
        <span>COMIENZA A IMPULSAR EL CRECIMIENTO DE TU NEGOCIO HOY MISMO.</span>
      </motion.h2>
      
      <motion.div
        ref={ref1}
        className="border-2 border-t-green-500 border-r-green-500 border-l-green-500 border-b-0 p-6 rounded-lg shadow-lg flex justify-around max-w-xl"
        variants={sectionVariants}
        initial="hidden"
        animate={inView1 ? "visible" : "hidden"}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <img src={logo} alt="Webwise Innovations" className="h-20 text-green-shadow" />
      </motion.div>
      
      <motion.div
        ref={ref2}
        className="p-6 rounded-lg shadow-lg text-center max-w-xl w-full"
        style={{ background: 'linear-gradient(to right, rgba(0, 151, 178, 0.4), rgba(126, 217, 87, 0.4))' }}
        variants={sectionVariants}
        initial="hidden"
        animate={inView2 ? "visible" : "hidden"}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <p className="mb-4">
          Haz crecer tu negocio atrayendo y convirtiendo clientes con redes sociales y una página web impactante.
        </p>
        
        <div className="text-left flex flexx justify-between">
          <ul className="list-disc list-inside mb-4 text-xl flex-1">
            <li>Logo minimalista y memorable</li>
            <li>Paleta de colores exclusiva</li>
            <li>Coherencia visual garantizada</li>
            <li>Feed de Instagram atractivo</li>
            <li>Mayor engagement y conexión</li>
          </ul>
          <ul className="list-disc list-inside mb-4 text-xl flex-1">
            <li>Descripciones claras y persuasivas</li>
            <li>Información de contacto accesible</li>
            <li>Navegación intuitiva y fluida</li>
            <li>Diseño responsivo y atractivo</li>
            <li>Imágenes llamativas y coherentes</li>
          </ul>
        </div>
        
        <button
          onClick={handleContactButtonClick}
          className="text-white py-2 px-6 rounded-full mb-4"
          style={{ background: 'linear-gradient(to right, rgba(0, 151, 178, 0.4), rgba(126, 217, 87, 0.4))' }}
        >
          CONTÁCTANOS PARA CONTRATAR TU SERVICIO
        </button>
        
        <div className="flex justify-center space-x-4">
          <img src={paypalLogo} alt="PayPal" className="h-10" />
          {/* <img src={anotherPaymentLogo} alt="Another Payment" className="h-10" /> */}
          {/* <img src={mercado} alt="MercadoPago" className="h-10" /> */}
          <img src={usdt} alt="USDT" className="h-10" />
        </div>
      </motion.div>
 
    </div>
  );
};

export default PromotionalSection;
