import React, { useState } from "react";
import logo from "../img/webwiseinnn.png";

export const Navbar = () => {
  // State to toggle the mobile menu
  const [isOpen, setIsOpen] = useState(false);

  // Function to handle the toggle of the mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle smooth scrolling to sections
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
    // Close the mobile menu after clicking on a link
    setIsOpen(false);
  };
  const handleWhatsAppButtonClick = () => {
    const phoneNumber = '+584241337562';
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, '_blank');
  };

  return (
    <nav className="flex items-center justify-between md:px-10 py-4 nav bg-transparent">
      {/* Logo */}
      <div className="flex items-center">
        <a href="/">
          <img src={logo} alt="Logo" className="h-16 md:h-10 mr-4" />
        </a>
      </div>

      {/* Mobile Menu */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-white text-xl">
          {/* Display hamburger icon if menu is closed, close icon if menu is open */}
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>
      </div>

      {/* Desktop Menu */}
      <div className="hidden md:flex items-center space-x-8 p-4">
        <button onClick={() => scrollToSection("planes-section")} className="text-white text-xl">Nosotros</button>
        <button onClick={() => scrollToSection("servicios-section")} className="text-white text-xl">Servicios</button>
        <button onClick={() => scrollToSection("saber-mas-section")} className="text-white text-xl">Saber más</button>
        <a href="https://blog.webwiseinn.com" target="_blank" className="text-white text-xl">Blog</a>
        <button  onClick={handleWhatsAppButtonClick} className="px-4 py-2 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-full hover:from-blue-500 hover:to-green-400 transition">CONTÁCTANOS</button>
      </div>

      {/* Mobile Menu Content */}
      {isOpen && (
        <div className="md:hidden absolute top-16 right-4 bg-black p-4 rounded-md z-50">
          <button onClick={() => scrollToSection("planes-section")} className="block text-white text-xl mb-2">Nosotros</button>
          <button onClick={() => scrollToSection("servicios-section")} className="block text-white text-xl mb-2">Servicios</button>
          <button onClick={() => scrollToSection("saber-mas-section")} className="block text-white text-xl mb-2">Saber más</button>
          <a href="https://blog.webwiseinn.com" target="_blank" className="block text-white text-xl mb-2">Blog</a>
          <button  onClick={handleWhatsAppButtonClick} className="block text-white text-xl">CONTÁCTANOS</button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
