 import './App.css';
import './css/style.css'

// import SomosLaSolicion from './components/somosLaSolucion';
import Footer from './components/footer';
import ScrollToTopButton from './components/ScrollToTopButton';
import Home from './pages/home';
// import Clientes from './components/clientes';
// import Importancia from './components/importancia';




  function App() {
  return (
    <div className="App">
<Home/>

 {/* <Importancia/> */}
 <ScrollToTopButton/>
 <Footer/>
    </div>
  );
}

export default App;
