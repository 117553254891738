import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img from '../iconos/cometa.png';
import img2 from '../iconos/astronauta.png';
import img3 from '../iconos/nave.png';
import img4 from '../iconos/comunicacion.png';
import img5 from '../iconos/flecha.png';

export const Barra = () => {
    const handleWhatsAppButtonClick = () => {
        const phoneNumber = '+584241337562';
        const whatsappLink = `https://wa.me/${phoneNumber}`;
        window.open(whatsappLink, '_blank');
    };

    const cuadroVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 }
    };

    const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
    const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
    const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });
    const { ref: ref4, inView: inView4 } = useInView({ triggerOnce: true });
    const { ref: ref5, inView: inView5 } = useInView({ triggerOnce: true });
    const { ref: refButton, inView: inViewButton } = useInView({ triggerOnce: true });

    return (
        <div id='planes-section' className="barra-container w-screen py-10 bg-black relative" style={{ background: 'linear-gradient(to top, #003d19,black,black)' }}>
            <div className="flex flex-col md:flex-row items-center justify-center gap-32">
                {[{ img: img, text: 'IMPRESIONA DESDE EL PRIMER CONTACTO', top: '-top-6', right: 'right-15', h: 'h-14', ref: ref1, inView: inView1 },
                { img: img2, text: 'TU EQUIPO CREATIVO', top: '-top-10', right: 'right-12', h: 'h-20', ref: ref2, inView: inView2 },
                { img: img3, text: 'IMPULSA TU MARCA PERSONAL', top: '-top-10', right: 'right-12', h: 'h-20', ref: ref3, inView: inView3 },
                { img: img4, text: 'COMUNICACIÓN CONSTANTE', top: '-top-12', right: 'right-15', h: 'h-20', ref: ref4, inView: inView4 },
                { img: img5, text: 'RESULTADOS SEMANALES', top: '-top-14', right: 'right-12', h: 'h-24', ref: ref5, inView: inView5 }]
                    .map((item, index) => (
                        <motion.div
                            key={index}
                            ref={item.ref}
                            className="cuadro flex flex-col items-center relative border border-green-500 p-4 rounded-lg h-36 w-48"
                            style={{ background: 'linear-gradient(to right, rgba(0, 151, 178, 0.4), rgba(126, 217, 87, 0.4))' }}
                            variants={cuadroVariants}
                            initial="hidden"
                            animate={item.inView ? 'visible' : 'hidden'}
                            transition={{ duration: 0.5, delay: index * 0.3 }}
                        >
                            <img src={item.img} className={`icono absolute ${item.top} ${item.right} ${item.h} p-1`} alt={item.text} />
                            <h3 className="text-2xl text-green-300 text-center mt-8">{item.text}</h3>
                        </motion.div>
                    ))}
            </div>
            <motion.div
                ref={refButton}
                className="flex justify-center mt-10 relative z-20"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={inViewButton ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5, delay: 1.5 }}
            >
                <motion.button
                    onClick={handleWhatsAppButtonClick}
                    className="bt px-10 sm:px-20 py-3 text-lg sm:text-2xl border border-green-500 text-white rounded"
                    whileHover={{ scale: 1.1 }}
                >
                    RESERVA UNA REUNIÓN CON NOSOTROS
                </motion.button>
            </motion.div>
         </div>
    );
}

export default Barra;
