import React from 'react';
import { motion } from 'framer-motion';
import Navbar from './navbar';
import videoFondo from '../videos/naveee.mp4';

export const Principal = () => {
  const handleWhatsAppButtonClick = () => {
    const phoneNumber = '+584241337562';
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className='fondo relative h-screen w-screen overflow-hidden'>
      {/* Video de fondo */}
      <video autoPlay loop muted preload="metadata" className="absolute top-0 left-0 min-w-full min-h-full object-cover z-0">
        <source src={videoFondo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Degradado */}
      <div className="overlay absolute top-0 left-0 w-full h-full z-10"></div>

      {/* Navbar */}
      <div className='relative z-20'>
        <Navbar />
      </div>

      {/* Contenido */}
      <div className='principal flex flex-col justify-center items-center text-center h-full relative z-30'>
        <motion.div 
          className='flex flex-col justify-center items-center px-4 text-white font-bold mt-40'
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <h1 className='text-4xl md:text-6xl'>
            TE AYUDAMOS ALCANZAR TU <br />
            MÁXIMO POTENCIAL EN EL <span>MUNDO DIGITAL</span><br />
            CONTRATA UN EQUIPO DE:
          </h1>
          <motion.div 
            className='mt-5 flex flex-col md:flex-row gap-4'
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <motion.button
              whileHover={{ scale: 1.1 }}
              className='px-4 py-2 border border-green-500 text-green-500 rounded hover:bg-green-500 hover:text-white transition'
            >
              CREACIÓN DE CONTENIDO
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              className='px-4 py-2 border border-green-500 text-green-500 rounded hover:bg-green-500 hover:text-white transition'
            >
              DESARROLLO WEB
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              className='px-4 py-2 border border-green-500 text-green-500 rounded hover:bg-green-500 hover:text-white transition'
            >
              PLAN COMPLETO
            </motion.button>
          </motion.div>
        </motion.div>
        <motion.div 
          className='flex justify-center mt-5 md:mt-10'
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          <motion.button 
            onClick={handleWhatsAppButtonClick} 
            className='text-2xl px-4 py-2 border border-green-500 text-white rounded hover:bg-green-500 hover:text-black transition'
            whileHover={{ scale: 1.1 }}
          >
            CONTÁCTANOS POR WHATSAPP
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};

export default Principal;
