import React from "react";
import img from '../clientes/LOGOS DS CYBER-07.png';
import security from '../clientes/security.png';

export const Clientes = () => {
  return (
    <div className="m-10">
      <h2 className="text-2xl  text-white py-2 px-4 rounded-lg mb-5 border-2 border-green-500  text-center w-52 mx-auto">
        CLIENTE 
      </h2>
      {/* aqui abajo */}
      <div className="clientes flex flex-col items-center mt-10 sm:flex-row sm:justify-center rounded-lg p-16" >
   <a href="https://dscyber.io/" target="_blanck" title="Ver sitio web">   <img src={img} className="h-28 m-5" /></a>
   <a href="https://www.instagram.com/securityresilience/" target="_blanck" title="Ver instagram"> <img src={security} className="h-28 m-5" /></a>
      </div>
    </div>
  );
}

export default Clientes;
