import React from "react";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const Servicios = () => {
  const handleWhatsAppButtonClick = () => {
    const phoneNumber = '+584241337562';
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, '_blank');
  };

  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });
  
  const sectionVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div id="servicios-section" className="bg-black text-white pt-10">
      <h2 className="text-2xl text-white py-2 px-4 rounded-lg mb-5 border-2 border-green-500 text-center w-52 mx-auto">
        SERVICIOS
      </h2>
      <h2 className="text-5xl text-center mb-8"><span>POTENCIA TU EMPRENDIMIENTO</span></h2>
      <div className="flex flex-col items-center pl-32 pr-32">
        <motion.div
          ref={ref1}
          className="derechaa responsivee borderr p-32 mb-10 flex flex-col md:flex-row items-center justify-between w-full"
          variants={sectionVariants}
          initial="hidden"
          animate={inView1 ? "visible" : "hidden"}
          transition={{ duration: 0.5 }}
        >
          <div></div>
          <div className="mb-4 md:mb-0 md:w-1/2 text-center">
            <div className="flex flex-col items-center">
              <h2 className="mt-4 text-5xl"><span>CREACIÓN DE CONTENIDO</span></h2>
            </div>
            <p className="m-6 text-2xl">Atrae a tus potenciales clientes con contenido creativo en tus redes sociales</p>
            <button onClick={handleWhatsAppButtonClick} className="h-14 responsive text-2xl w-80 rounded-full button" style={{ background: 'linear-gradient(to right, rgba(0, 151, 178, 0.4), rgba(126, 217, 87, 0.4))' }}>CONSULTAR</button>
          </div>
        </motion.div>
        <motion.div
          ref={ref2}
          className="izquierda responsivee p-32 mb-10 borderr flex flex-col md:flex-row items-center justify-between w-full"
          variants={sectionVariants}
          initial="hidden"
          animate={inView2 ? "visible" : "hidden"}
          transition={{ duration: 0.5 }}
        >
          <div className="text-center md:w-1/2">
            <div className="flex flex-col items-center">
              <h2 className="mt-4 text-5xl"><span>DESARROLLO WEB</span></h2>
            </div>
            <p className="m-6 text-2xl">Convierte tus prospectos en clientes efectivos con una Página Web</p>
            <button onClick={handleWhatsAppButtonClick} className="h-14 responsive text-2xl w-80 rounded-full button" style={{ background: 'linear-gradient(to right, rgba(0, 151, 178, 0.4), rgba(126, 217, 87, 0.4))' }}>CONSULTAR</button>
          </div>
        </motion.div>
        <motion.div
          ref={ref3}
          className="derecha responsivee p-32 borderr flex flex-col md:flex-row items-center justify-between w-full"
          variants={sectionVariants}
          initial="hidden"
          animate={inView3 ? "visible" : "hidden"}
          transition={{ duration: 0.5 }}
        >
          <div></div>
          <div className="text-center md:w-1/2 mb-4 md:mb-0">
            <div className="flex flex-col items-center">
              <h2 className="mt-4 text-5xl"><span>PLAN COMPLETO</span></h2>
            </div>
            <p className="m-6 text-2xl">Impulsa tu emprendimiento atrayendo y convirtiendo clientes a través de redes sociales y una página web</p>
            <button onClick={handleWhatsAppButtonClick} className="h-14 responsive text-2xl w-80 rounded-full button" style={{ background: 'linear-gradient(to right, rgba(0, 151, 178, 0.4), rgba(126, 217, 87, 0.4))' }}>CONSULTAR</button>
          </div>
        </motion.div>
      </div>
      
    </div>
  );
};

export default Servicios;
